exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._27QRZfI4H3V_HvPqRIDk_E h2,.cb-widget ._27QRZfI4H3V_HvPqRIDk_E h3{font-size:20px}@media (max-width: 768px){.cb-widget ._27QRZfI4H3V_HvPqRIDk_E .cb-btn{display:block;width:100%}}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E .brRZ5BMbWCvJQ1zkRlfrY{border:1px solid #d9d9d9}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._2vBQUfM1vX2Q6dMZiCcDno{padding:24px}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._2vBQUfM1vX2Q6dMZiCcDno ._1NEDEcoH0sWkeNGjsoekeV ._1j0It2TyGNz9yELV0vqJuI{display:flex;align-items:center;margin-bottom:24px}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._2vBQUfM1vX2Q6dMZiCcDno ._1NEDEcoH0sWkeNGjsoekeV ._1j0It2TyGNz9yELV0vqJuI span{flex-shrink:0;margin-right:12px;width:56px;height:56px;font-size:56px}@media (max-width: 768px){.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._2vBQUfM1vX2Q6dMZiCcDno ._1NEDEcoH0sWkeNGjsoekeV ._1j0It2TyGNz9yELV0vqJuI h2{line-height:1.0}}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._2vBQUfM1vX2Q6dMZiCcDno ._1NEDEcoH0sWkeNGjsoekeV .L_rL_2XoAn3rHeCwbKovM{margin-bottom:24px}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._2vBQUfM1vX2Q6dMZiCcDno ._1NEDEcoH0sWkeNGjsoekeV ._30vLShGYMHnlBTBt4-7N-h p{text-align:center}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._1_ZTHTe65R8zstNfLYs215 .brRZ5BMbWCvJQ1zkRlfrY+.brRZ5BMbWCvJQ1zkRlfrY{margin-top:24px}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._1_ZTHTe65R8zstNfLYs215 ._3GW1l-dmVpJzj5P6g4Bb6T{text-align:center}.cb-widget ._27QRZfI4H3V_HvPqRIDk_E ._1_ZTHTe65R8zstNfLYs215 ._3GW1l-dmVpJzj5P6g4Bb6T h3{margin-bottom:12px}\n", ""]);

// exports
exports.locals = {
	"root": "_27QRZfI4H3V_HvPqRIDk_E",
	"container": "brRZ5BMbWCvJQ1zkRlfrY",
	"wrapper": "_2vBQUfM1vX2Q6dMZiCcDno",
	"form": "_1NEDEcoH0sWkeNGjsoekeV",
	"header": "_1j0It2TyGNz9yELV0vqJuI",
	"body": "L_rL_2XoAn3rHeCwbKovM",
	"footer": "_30vLShGYMHnlBTBt4-7N-h",
	"loggedOut": "_1_ZTHTe65R8zstNfLYs215",
	"callToAction": "_3GW1l-dmVpJzj5P6g4Bb6T"
};