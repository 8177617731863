export default {
    signIn: 'Sign In',
    globalNavigation: 'Global Navigation',
    openGlobalNavigation: 'Open Global Navigation Panel',
    closeGlobalNavigation: 'Close Global Navigation Panel',
    loginPanel: 'Login Panel',
    openLoginPanel: 'Open Sign In Panel',
    searchPanel: 'Search Panel',
    openSearchPanel: 'Open Search Panel',
    closeLoginPanel: 'Close Login Panel',
    closeSearchPanel: 'Close Sign In Panel',
};
/*
export default localize({
  signIn: ['Sign In', 'Iniciar Sesión'],
  globalNavigation: ['Global Navigation', 'Navegación Global'],
  openGlobalNavigation: ['Open Global Navigation Panel', 'Cerrar el Panel de Navegación Global'],
  closeGlobalNavigation: ['Close Global Navigation Panel', 'Abrir el Panel de Navegación Global'],
  loginPanel: ['Login Panel', 'Panel de inicio de sesión'],
  openLoginPanel:  ['Open Sign In Panel', 'Abrir el panel de inicio de sesión'],
  searchPanel: ['Search Panel', 'Panel de búsqueda'],
  openSearchPanel: ['Open Search Panel', 'Abre el Panel de Búsqueda'],
  closeLoginPanel: ['Close Login Panel', 'Cerrar el panel de inicio de sesión'],
  closeSearchPanel: ['Close Sign In Panel', 'Cerrar el panel de búsqueda']
})
*/
