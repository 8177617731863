var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable, computed } from 'mobx';
import jsonp from 'jsonp-promise';
import { toLower, sortBy, startsWith } from 'lodash';
import { URL, dpStore } from '@widgets/core';
var defaults = {
    limit: 10,
    sensitivity: 2,
    usePredefinedSearchType: true,
    startValue: null,
    siteType: null,
    searchType: 'site',
    facetList: ["Audience", "Type", "Category"],
    facetValues: null,
    destinationURL: null,
    additionalParams: null,
    onSubmit: null
};
var SearchStore = /** @class */ (function () {
    function SearchStore(retrieve) {
        var _this = this;
        this.retrieve = retrieve;
        this.suggestions = observable([]);
        this.fetchSuggestions = function (term) { return __awaiter(_this, void 0, void 0, function () {
            var unsortedSuggestions, sortedSuggestions, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.retrieve(term)];
                    case 1:
                        unsortedSuggestions = _a.sent();
                        sortedSuggestions = sortBy(unsortedSuggestions, function (s) { return startsWith(toLower(s.label), toLower(term)) ? 0 : 1; });
                        this.suggestions.replace(sortedSuggestions);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        this.clear = function () {
            _this.suggestions.clear();
        };
        this.props = null;
    }
    Object.defineProperty(SearchStore.prototype, "config", {
        get: function () {
            return __assign(__assign(__assign({}, defaults), this.props), (dpStore.dp.search.config));
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], SearchStore.prototype, "props", void 0);
    __decorate([
        computed
    ], SearchStore.prototype, "config", null);
    return SearchStore;
}());
export { SearchStore };
export var generateSearchStore = function () {
    var _this = this;
    return new SearchStore(function (term) { return __awaiter(_this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL('https://content.atomz.com/autocomplete/sp10/04/43/11')
                        .setQueryParam('query', term).setQueryParam('max_results', '1000')
                        .href;
                    return [4 /*yield*/, jsonp(url, { timeout: 3000 }).promise];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.map(function (str, index) { return ({
                            id: index,
                            label: str,
                            value: str.replace(/ /g, '-')
                        }); })];
            }
        });
    }); });
};
export var collegeSearchStore = new SearchStore(function (term) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL('https://public.cbapis.org/bscsuggestions/suggestions')
                    .setQueryParam('q', term).setQueryParam('api-key', 'cbwww-e99be64d-d782-4fa7-a915-6a4a5b4185b2').setQueryParam('max_results', '1000')
                    .href;
                return [4 /*yield*/, jsonp(url, { timeout: 3000 }).promise];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.suggestionResponses.map(function (item, index) { return ({
                        id: Number(item.id),
                        label: item.name,
                        value: item.name.replace(/ /g, '-'),
                        diCode: item.diCode
                    }); })];
        }
    });
}); });
