import React from 'react';
import FooterStore from './store';
import { Footer } from './GlobalFooterWidget';
import { widget, globalStore } from '@widgets/core';
import { TweenLite } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
var plugins = [ScrollToPlugin]; // NEEDED in production so it's not tree-shaken out!
var scrollToGlobalHeader = function (e) {
    e.preventDefault();
    TweenLite.to(window, 0.3, {
        scrollTo: { y: 0 },
        onComplete: function () { return globalStore.globalHeaderWrapper.focus(); }
    });
};
export var FooterWidget = widget('footer')(function () { return new FooterStore; }, function (store, tracker) {
    return (React.createElement(Footer, { tracker: tracker, showBackToTopLink: store.config.showBackToTopLink, showLinks: store.config.showLinks, programLinks: store.programLinks, organizationLinks: store.organizationLinks, extraLinks: store.extraLinks, globalHeaderId: globalStore.globalHeaderWrapper ? globalStore.globalHeaderWrapper.id : '', topClicked: scrollToGlobalHeader }));
});
export default FooterWidget;
