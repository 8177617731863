var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from 'mobx';
import { dpStore, levityStore as levity, URL } from '@widgets/core';
import { filter, each, defaultsDeep } from 'lodash';
import identityWidgetLinks from './links';
import endpointTemplates from './endpointTemplates';
import { fromPromise } from 'mobx-utils';
import axios from 'axios';
import { merge } from 'lodash';
import Cookie from 'js-cookie';
export function addQueryParam(url, name, value) {
    var u = new URL(url);
    u.setQueryParam(name, value);
    return u.href;
}
var IdentityWidgetStore = /** @class */ (function () {
    function IdentityWidgetStore() {
    }
    Object.defineProperty(IdentityWidgetStore.prototype, "config", {
        get: function () {
            return defaultsDeep({ endpointParams: {} }, dpStore.dp.identity.config, this.props);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IdentityWidgetStore.prototype, "redirectUrl", {
        get: function () {
            var defaultUrl = /iframe/.test(window.location.href) ? window.parent.location.href : window.location.href;
            var url = new URL(this.config.redirectUrl || defaultUrl);
            url.removeQueryParam('TST');
            url.removeQueryParam('userName');
            return url.href;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IdentityWidgetStore.prototype, "rememberMe", {
        get: function () {
            var username = Cookie.get('rememberMe') || '';
            return username;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IdentityWidgetStore.prototype, "linksPromise", {
        get: function () {
            var apiUrl = 'https://mango.collegeboard.org/cbmango1/prod/api/all/1/identity-links.json';
            var linksPromise = axios.get(apiUrl).then(function (res) { return res.data; });
            this.tracker.watchPromise(linksPromise, 'fetch identity links');
            return fromPromise(linksPromise);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IdentityWidgetStore.prototype, "links", {
        get: function () {
            return this.linksPromise && this.linksPromise.state === 'fulfilled' ? this.linksPromise.value : identityWidgetLinks;
        },
        enumerable: false,
        configurable: true
    });
    IdentityWidgetStore.prototype.endpoint = function (name) {
        var endpoints = merge(endpointTemplates, {
            authentication: this.config.authenticationUrl
        });
        var url = new URL(endpoints[name].replace('%appId', String(dpStore.appId)).replace('%DURL', this.redirectUrl));
        url.setQueryParams(this.config.endpointParams[name]);
        return url.href;
    };
    Object.defineProperty(IdentityWidgetStore.prototype, "allLinks", {
        get: function () {
            var siteCode = this.config.siteCode;
            return each(filter(this.links, function (link) { return link.type === levity.userType; }), function (link) {
                link.url = addQueryParam(link.url, 'navId', siteCode ? siteCode + "-" + link.linkCode : link.linkCode);
            });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], IdentityWidgetStore.prototype, "props", void 0);
    __decorate([
        computed
    ], IdentityWidgetStore.prototype, "config", null);
    __decorate([
        computed
    ], IdentityWidgetStore.prototype, "redirectUrl", null);
    __decorate([
        computed
    ], IdentityWidgetStore.prototype, "rememberMe", null);
    __decorate([
        computed
    ], IdentityWidgetStore.prototype, "linksPromise", null);
    __decorate([
        computed
    ], IdentityWidgetStore.prototype, "links", null);
    __decorate([
        computed
    ], IdentityWidgetStore.prototype, "allLinks", null);
    return IdentityWidgetStore;
}());
export default IdentityWidgetStore;
