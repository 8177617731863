var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from 'mobx';
import defaultLinks from './defaultHeaderLinks';
import { remove, cloneDeep } from 'lodash';
import axios from 'axios';
import { dpStore, URL } from '@widgets/core';
import { fromPromise } from 'mobx-utils';
import { Defaults } from './config';
// TODO move this to widgets-core, and find another URL solution if it doesn't work on IE
function addQueryParam(url, name, value) {
    var u = new URL(url);
    u.setQueryParam(name, value);
    return u.href;
}
var HeaderStore = /** @class */ (function () {
    function HeaderStore() {
        this.props = null;
    }
    Object.defineProperty(HeaderStore.prototype, "config", {
        get: function () {
            return __assign(__assign(__assign({}, Defaults), this.props), (dpStore.dp.header));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HeaderStore.prototype, "useService", {
        get: function () {
            return this.config.fetchLinks && !!this.config.linksURL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HeaderStore.prototype, "linksPromise", {
        get: function () {
            if (this.useService) {
                var linksPromise = axios.get(this.config.linksURL).then(function (res) { return res.data; });
                this.tracker.watchPromise(linksPromise, 'GET Header Links');
                return fromPromise(linksPromise);
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HeaderStore.prototype, "links", {
        get: function () {
            return this.formatLinks(this.linksPromise && this.linksPromise.state === 'fulfilled' ? this.linksPromise.value : defaultLinks);
        },
        enumerable: false,
        configurable: true
    });
    HeaderStore.prototype.formatLinks = function (rawLinks) {
        var links = cloneDeep(rawLinks);
        var siteCode = this.config.siteCode === undefined ? 'gh' : this.config.siteCode;
        links.forEach(function (link) {
            link.url = addQueryParam(link.url, 'navId', siteCode + "-" + link.linkCode);
        });
        for (var _i = 0, links_1 = links; _i < links_1.length; _i++) {
            var link = links_1[_i];
            link.children = remove(links, { group: link.name });
        }
        return links;
    };
    Object.defineProperty(HeaderStore.prototype, "k12Links", {
        get: function () {
            return this.links.filter(function (l) { return l.category === 'K-12'; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HeaderStore.prototype, "accessLinks", {
        get: function () {
            return this.links.filter(function (l) { return l.category === 'Access'; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HeaderStore.prototype, "higherEdLinks", {
        get: function () {
            return this.links.filter(function (l) { return l.category === 'Higher Ed'; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HeaderStore.prototype, "organizationLinks", {
        get: function () {
            return this.links.filter(function (l) { return l.category === 'Organization'; });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], HeaderStore.prototype, "props", void 0);
    __decorate([
        computed
    ], HeaderStore.prototype, "config", null);
    __decorate([
        computed
    ], HeaderStore.prototype, "useService", null);
    __decorate([
        computed
    ], HeaderStore.prototype, "linksPromise", null);
    __decorate([
        computed
    ], HeaderStore.prototype, "links", null);
    __decorate([
        computed
    ], HeaderStore.prototype, "k12Links", null);
    __decorate([
        computed
    ], HeaderStore.prototype, "accessLinks", null);
    __decorate([
        computed
    ], HeaderStore.prototype, "higherEdLinks", null);
    __decorate([
        computed
    ], HeaderStore.prototype, "organizationLinks", null);
    return HeaderStore;
}());
export default HeaderStore;
