import { observable } from 'mobx';
import debounce from 'lodash/debounce';
function getViewportSize() {
    var width = window.innerWidth;
    return (width < 768 ? 'mobile' :
        width < 1024 ? 'tablet' :
            width < 1024 ? 'desktop' :
                'oversize');
}
var viewport = observable({
    size: getViewportSize(),
    get isMobile() {
        return this.size === 'mobile';
    }
});
window.addEventListener('resize', debounce(function () {
    viewport.size = getViewportSize();
}, 100));
export default viewport;
