var templates = {
    professional: 'https://account.collegeboard.org/professional/dashboard',
    student: 'https://student.collegeboard.org/',
    logout: 'https://account.collegeboard.org/login/logout',
    authentication: 'https://account.collegeboard.org/login/authenticateUser',
    signUp: 'https://account.collegeboard.org/login/signUp?appId=%appId&DURL=%DURL',
    forgotUsername: 'https://account.collegeboard.org/login/forgotUsername?appId=%appId&DURL=%DURL',
    forgotPassword: 'https://account.collegeboard.org/login/forgotPassword?appId=%appId&DURL=%DURL'
};
export default templates;
