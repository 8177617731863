exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._1QETx0h1q89QxFDwiUPP_A{display:flex;align-items:center;justify-content:center;height:100%;padding-right:4px;padding-left:4px;margin-left:4px;margin-right:4px;min-width:44px}.cb-widget ._1QETx0h1q89QxFDwiUPP_A:active,.cb-widget ._1QETx0h1q89QxFDwiUPP_A:link,.cb-widget ._1QETx0h1q89QxFDwiUPP_A:visited,.cb-widget ._1QETx0h1q89QxFDwiUPP_A:hover{text-decoration:none;color:#221f1f}.cb-widget ._1QETx0h1q89QxFDwiUPP_A ._2doZro9uxGOuA7_6JJMIaW{margin-right:4px;font-size:14px}\n", ""]);

// exports
exports.locals = {
	"root": "_1QETx0h1q89QxFDwiUPP_A",
	"name": "_2doZro9uxGOuA7_6JJMIaW"
};