var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { observable, autorun } from 'mobx';
import { set, once, debounce, invoke, groupBy, remove } from 'lodash';
import pick from 'lodash/fp/pick';
import WidgetInstance from './WidgetInstance';
import Tracker from './Tracker';
export default (function (widgetName, teamName) {
    if (teamName === void 0) { teamName = 'custom'; }
    var instance = new WidgetInstance(widgetName, teamName);
    allInstances.push(instance);
    return new Tracker(instance);
});
var manualMode = false;
var AUTO_NOTIFY = 4000;
var WAIT = 1000;
var notifySatellite = function () {
    invoke(window, '_satellite.track', 'cbTrack.widgetsReady');
};
var manualNotify = debounce(function () { notifySatellite(); }, WAIT);
var autoNotify = once(function () {
    notifySatellite();
    manualMode = true;
});
window.setTimeout(function () { autoNotify(); }, AUTO_NOTIFY);
var notifyEarlyIfNoFurtherActivity = debounce(function () { autoNotify(); }, WAIT);
var allInstances = observable([]);
autorun(function () {
    remove(allInstances, function (i) {
        return i.toRemove === true;
    });
    var rawInstances = allInstances.map(function (i) { return (__assign(__assign({}, pick(['id', 'widgetName', 'teamName', 'props', 'status', 'componentMounted', 'componentError', 'errorLocation'], i)), { requests: i.requests.map(pick(['name', 'state', 'isSlow', 'errorMessage', 'duration'])) })); });
    set(window, 'cbTrackData.atlas', groupBy(rawInstances, 'widgetName'));
    if (manualMode) {
        manualNotify();
    }
});
autorun(function () {
    if (allInstances.length && allInstances.every(function (i) { return i.status === 'ready'; }))
        notifyEarlyIfNoFurtherActivity();
});
