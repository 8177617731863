exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._3K1EdqYTskFwel7heBcw8z{width:250px}.cb-widget ._2zA7me9faiGbU_HvFFymDk{display:flex;justify-content:center;align-items:center}\n", ""]);

// exports
exports.locals = {
	"root": "_3K1EdqYTskFwel7heBcw8z",
	"control": "_2zA7me9faiGbU_HvFFymDk"
};