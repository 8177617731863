var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from 'mobx';
import defaultLinks from './defaultFooterLinks';
import { remove, cloneDeep } from 'lodash';
import axios from 'axios';
import { dpStore, URL } from '@widgets/core';
import { fromPromise } from 'mobx-utils';
// TODO move this to widgets-core, and find another URL solution if it doesn't work on IE
function addQueryParam(url, name, value) {
    var u = new URL(url);
    u.setQueryParam(name, value);
    return u.href;
}
var defaultFooterConfig = {
    fetchLinks: true,
    linksURL: 'https://mango.collegeboard.org/cbmango1/dev/api/all/1/globalfooter-links.json',
    analyticsSiteCode: 'gf',
    showLinks: true,
    showBackToTopLink: true
};
var FooterStore = /** @class */ (function () {
    function FooterStore() {
        this.props = null;
    }
    Object.defineProperty(FooterStore.prototype, "config", {
        get: function () {
            return __assign(__assign(__assign({}, defaultFooterConfig), this.props), (dpStore.dp.footer.config));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FooterStore.prototype, "useService", {
        get: function () {
            return this.config.fetchLinks && !!this.config.linksURL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FooterStore.prototype, "linksPromise", {
        get: function () {
            var linksPromise = axios.get(this.config.linksURL);
            this.tracker.watchPromise(linksPromise, 'fetch footer links');
            return this.useService && fromPromise(linksPromise.then(function (res) { return res.data; }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FooterStore.prototype, "links", {
        get: function () {
            return this.formatLinks(this.linksPromise && this.linksPromise.state === 'fulfilled' ? this.linksPromise.value : defaultLinks);
        },
        enumerable: false,
        configurable: true
    });
    FooterStore.prototype.formatLinks = function (rawLinks) {
        var links = cloneDeep(rawLinks);
        var siteCode = this.config.analyticsSiteCode;
        links.forEach(function (link) {
            link.url = addQueryParam(link.url, 'navId', siteCode + "-" + link.linkCode);
        });
        for (var _i = 0, links_1 = links; _i < links_1.length; _i++) {
            var link = links_1[_i];
            link.children = remove(links, { group: link.name });
        }
        return links;
    };
    Object.defineProperty(FooterStore.prototype, "programLinks", {
        get: function () {
            return this.links.filter(function (l) { return l.category === 'programs'; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FooterStore.prototype, "organizationLinks", {
        get: function () {
            return this.links.filter(function (l) { return l.category === 'organization'; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FooterStore.prototype, "extraLinks", {
        get: function () {
            return this.links.filter(function (l) { return l.category === 'extra'; });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], FooterStore.prototype, "props", void 0);
    __decorate([
        computed
    ], FooterStore.prototype, "config", null);
    __decorate([
        computed
    ], FooterStore.prototype, "useService", null);
    __decorate([
        computed
    ], FooterStore.prototype, "linksPromise", null);
    __decorate([
        computed
    ], FooterStore.prototype, "links", null);
    __decorate([
        computed
    ], FooterStore.prototype, "programLinks", null);
    __decorate([
        computed
    ], FooterStore.prototype, "organizationLinks", null);
    __decorate([
        computed
    ], FooterStore.prototype, "extraLinks", null);
    return FooterStore;
}());
export default FooterStore;
