import Request from './Request';
import defer from 'lodash/defer';
var Tracker = /** @class */ (function () {
    function Tracker(instance) {
        this.instance = instance;
    }
    Object.defineProperty(Tracker.prototype, "id", {
        get: function () {
            return this.instance.id;
        },
        enumerable: false,
        configurable: true
    });
    Tracker.prototype.mounted = function (props) {
        this.instance.componentMounted = true;
        if (props) {
            this.instance.props = props;
        }
    };
    Tracker.prototype.unmounted = function () {
        this.instance.componentMounted = false;
        this.instance.toRemove = true;
    };
    Tracker.prototype.componentError = function (e, location) {
        this.instance.componentError = e;
        if (location)
            this.instance.errorLocation = location;
    };
    Tracker.prototype.watchPromise = function (promise, name) {
        var _this = this;
        defer(function () { return _this.instance.requests.push(new Request(name, promise)); });
    };
    return Tracker;
}());
export default Tracker;
