var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Fragment } from 'react';
import LoginControl from '../LoginControl';
import SearchControl from '../SearchControl';
import includes from 'lodash/includes';
import $ from 'classnames';
import styles from '../styles.module.scss';
import localStyles from './styles.module.scss';
import strings from '../l10n';
var afterRender = function (fn) { setTimeout(fn, 0); };
var ControlsView = /** @class */ (function (_super) {
    __extends(ControlsView, _super);
    function ControlsView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loginControl = React.createRef();
        _this.searchControl = React.createRef();
        _this.closeLink = React.createRef();
        return _this;
    }
    ControlsView.prototype.focusOnCloseIcon = function () {
        this.closeLink.current.focus();
    };
    ControlsView.prototype.focusOnLoginControl = function () {
        var _this = this;
        afterRender(function () { _this.loginControl.current.focus(); });
    };
    ControlsView.prototype.focusOnSearchControl = function () {
        var _this = this;
        afterRender(function () { _this.searchControl.current.focus(); });
    };
    ControlsView.prototype.render = function () {
        var _this = this;
        var _a = this.props, activePanel = _a.activePanel, togglePanel = _a.togglePanel, useSearchWidget = _a.useSearchWidget, useLoginWidget = _a.useLoginWidget, searchConfig = _a.searchConfig;
        var isOpen = includes(['login', 'search'], activePanel);
        var closePanelLabel = activePanel === 'login' ? strings.closeLoginPanel : strings.closeSearchPanel;
        var clickedControl = function (panel) { return function (e) {
            e.preventDefault();
            togglePanel(panel);
            afterRender(function () { return _this.closeLink.current.focus(); });
        }; };
        var closeLinkClicked = function (e) {
            e.preventDefault();
            togglePanel(null);
            afterRender(function () { return (activePanel === 'login' ? _this.loginControl : _this.searchControl).current.focus(); });
        };
        return (React.createElement("div", { className: $(styles.items, styles.controls) },
            React.createElement("div", { className: styles.items }, !isOpen ?
                React.createElement(Fragment, null,
                    useLoginWidget && React.createElement(LoginControl, { ref: this.loginControl, onClick: clickedControl('login') }),
                    useSearchWidget && React.createElement(SearchControl, { searchConfig: searchConfig, ref: this.searchControl, onClick: clickedControl('search') })) :
                React.createElement("a", { ref: this.closeLink, className: $(styles.control, localStyles.root), "aria-label": closePanelLabel, href: "#close-panel", onClick: closeLinkClicked, "data-cbtrack-linktype": "toggle" },
                    React.createElement("span", { "aria-hidden": "true", className: 'cb-glyph cb-x-mark' })))));
    };
    return ControlsView;
}(React.Component));
export default ControlsView;
