exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cHf9AjoXrgVIYsjFRaUle{position:fixed;background-color:white;z-index:899;opacity:0.8;top:0;right:0;bottom:0;left:0}._393NAU4tFedM5C24dCVE0s{position:fixed;width:100%}\n", ""]);

// exports
exports.locals = {
	"overlay": "cHf9AjoXrgVIYsjFRaUle",
	"positionFixed": "_393NAU4tFedM5C24dCVE0s"
};