exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._3qvg7YLaD3BzMbddu7j-sd{max-height:calc(100vh - 44px)}.cb-widget ._6RBKy0U4kLIawjStA2efQ{overflow:hidden}.cb-widget ._39n3txVghbGzEGjsab8BhR{background-color:white;height:0px;position:relative;width:100%}.cb-widget ._39n3txVghbGzEGjsab8BhR>div{position:absolute;bottom:0px;width:100%}.cb-widget ._1Wd81l9GWjOm6MfG_nPGUJ{transition:height 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955)}\n", ""]);

// exports
exports.locals = {
	"panel": "_3qvg7YLaD3BzMbddu7j-sd",
	"overflowHidden": "_6RBKy0U4kLIawjStA2efQ",
	"panelInner": "_39n3txVghbGzEGjsab8BhR",
	"transition": "_1Wd81l9GWjOm6MfG_nPGUJ"
};