var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import { apricot4 } from "@widgets/core";
import $ from "classnames";
import styles from "./styles.module.scss";
import { LoginFormView, IdentityLinksView, CallToActionView } from "../views/";
import localization from "../localization";
var HeroIdentityCore = /** @class */ (function (_super) {
    __extends(HeroIdentityCore, _super);
    function HeroIdentityCore() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HeroIdentityCore.prototype.render = function () {
        var _a = this.props, allLinks = _a.allLinks, isLoggedIn = _a.isLoggedIn, userType = _a.userType, userName = _a.userName, logoutUrl = _a.logoutUrl, redirectUrl = _a.redirectUrl, signupUrl = _a.signupUrl, endpointParams = _a.endpointParams, appId = _a.appId, tracker = _a.tracker, authenticationUrl = _a.authenticationUrl, forgotUsernameUrl = _a.forgotUsernameUrl, forgotPasswordUrl = _a.forgotPasswordUrl, imageUrl = _a.imageUrl, preambleText = _a.preambleText, headerText = _a.headerText, bodyText = _a.bodyText, program = _a.program, bgColor = _a.bgColor;
        var inlineStyle;
        if (program === "custom") {
            inlineStyle = {
                "background-color": bgColor,
            };
        }
        var a4Col1 = $("col-xs-12", "col-sm-6", "col-md-7", "col-lg-7", "offset-xl-0", "col-xl-7", "offset-2xl-0", "col-2xl-7", "offset-3xl-1", "col-3xl-5");
        var a3Col2 = $("col-xs-12", "col-sm-6", "col-md-offset-1", "col-md-5");
        var a4Col2 = $("col-xs-12", "col-sm-6", "col-md-5", "col-lg-5", "col-xl-5", "col-2xl-5", "col-3xl-5");
        var a3Col1 = $("col-xs-12", "col-sm-6", "col-lg-5", "col-lg-offset-1");
        return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "identity", className: $("cb-widget", styles.root, !apricot4 && "cb-apricot3") },
            React.createElement("div", { className: $(styles.background, styles[program]), style: inlineStyle }),
            React.createElement("div", { className: $(styles.focus) },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: $(apricot4 && a4Col1, !apricot4 && a3Col1) },
                            React.createElement("div", { className: $(styles.hero) },
                                React.createElement("img", { className: $(styles.transition), src: !!imageUrl
                                        ? imageUrl
                                        : "https://widgets.fed-nonprod.collegeboard.org/content/focus-device.png" }),
                                React.createElement("div", { className: $(styles.content) },
                                    preambleText && React.createElement("p", { className: $(styles.preambleText) }, preambleText),
                                    React.createElement("h2", null, headerText),
                                    bodyText && React.createElement("p", { dangerouslySetInnerHTML: { __html: bodyText } })))),
                        React.createElement("div", { className: $(apricot4 && a4Col2, !apricot4 && a3Col2) },
                            React.createElement("div", { className: $(styles.login) }, isLoggedIn ? (React.createElement("div", { className: $(styles.container) },
                                React.createElement("div", { className: $(styles.wrapper) },
                                    React.createElement(IdentityLinksView, { allLinks: allLinks, username: userName, userType: userType, redirectUrl: redirectUrl, logoutUrl: logoutUrl, appId: appId, theme: styles })))) : (React.createElement("div", { className: $(styles.loggedOut) },
                                React.createElement("div", { className: $(styles.container) },
                                    React.createElement("div", { className: $(styles.wrapper) },
                                        React.createElement(LoginFormView, { authenticationUrl: authenticationUrl, forgotUsernameUrl: forgotUsernameUrl, forgotPasswordUrl: forgotPasswordUrl, redirectUrl: redirectUrl, endpointParams: endpointParams, appId: appId, theme: styles }))),
                                React.createElement("div", { className: $(styles.container) },
                                    React.createElement("div", { className: $(styles.wrapper) },
                                        React.createElement(CallToActionView, { href: signupUrl, title: localization.dontHaveAccount, label: localization.signUp, theme: styles }))))))))))));
    };
    HeroIdentityCore.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    HeroIdentityCore.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    return HeroIdentityCore;
}(Component));
export default HeroIdentityCore;
