var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, computed } from 'mobx';
import { memoize } from 'lodash';
var instanceId = memoize(function (widgetName, teamName, count) {
    if (count === void 0) { count = 1; }
    return function () {
        return "cb-" + teamName.replace(/ /g, '-').toLowerCase() + "-" + widgetName.replace(/ /g, '-').toLowerCase() + "-" + count++;
    };
});
var WidgetInstance = /** @class */ (function () {
    function WidgetInstance(widgetName, teamName) {
        this.widgetName = widgetName;
        this.teamName = teamName;
        this.id = instanceId(this.widgetName, this.teamName)();
        this.requests = observable([]);
        this.componentMounted = false;
        this.toRemove = false;
        this.componentError = null;
        this.errorLocation = null;
        this.props = null;
    }
    Object.defineProperty(WidgetInstance.prototype, "status", {
        get: function () {
            return this.requests.some(function (r) { return r.state === 'error'; }) || this.componentError ? 'error' :
                this.requests.some(function (r) { return r.state === 'pending'; }) ? 'pending' :
                    this.componentMounted ? 'ready' :
                        'initializing';
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        observable
    ], WidgetInstance.prototype, "componentMounted", void 0);
    __decorate([
        observable
    ], WidgetInstance.prototype, "toRemove", void 0);
    __decorate([
        observable
    ], WidgetInstance.prototype, "componentError", void 0);
    __decorate([
        observable
    ], WidgetInstance.prototype, "errorLocation", void 0);
    __decorate([
        observable
    ], WidgetInstance.prototype, "props", void 0);
    __decorate([
        computed
    ], WidgetInstance.prototype, "status", null);
    return WidgetInstance;
}());
export default WidgetInstance;
