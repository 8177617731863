var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import $ from 'classnames';
import { DefaultSearch } from '@widgets/search';
import { concat, merge, find, reject } from 'lodash';
import queryString from 'querystring';
import { FilterView, StatusView, BannersView, SearchResultsView, FilterControlsView, PaginationView } from './views';
var TrackingLink = function (p) {
    var children = p.children, url = p.url;
    var attrs = {
        href: url
    };
    return React.createElement("a", __assign({}, attrs), children);
};
var historyList = [];
var SiteSearch = /** @class */ (function (_super) {
    __extends(SiteSearch, _super);
    function SiteSearch(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isBrowserBack: false,
            siteType: null,
            word: null,
            data: {
                isDataAvailable: false,
                resetURL: null,
                pagination: null,
                results: null,
                columns: null,
                activeFilters: null,
                resultStats: null,
                banners: null
            },
            isRetrievingData: false,
            isFiltersOn: false,
            isFiltersActive: false,
            params: {},
            additionalParams: {},
            startingParams: {}
        };
        // Get searchParams via URL. This is for when people bookmark search results.
        var query = window.top.location.search.replace('?', '');
        /**
         * We can also pass in searchParams. If we pass in values, those params will
         * overwrite the url params and are deemed more important what is in the url.
        */
        _this.state.startingParams = _this.state.params = props.searchParams
            ? (_this.sanitizeSearchParameters(props.searchParams, props.additionalParams))
            : (_this.sanitizeSearchParameters(queryString.parse(query), props.additionalParams));
        /**
         * Store the site type if we got it
         */
        _this.state.siteType = _this.props.siteType;
        /**
         * If we have a valid search definition (word, tp and searchType), go ahead and search immediately.
         */
        if (_this.isValidSearch(_this.state.params)) {
            _this.state.word = _this.state.params['word'];
            _this.state.isRetrievingData = true;
        }
        else {
            _this.sendAnalytics('start');
        }
        return _this;
    }
    SiteSearch.prototype.isValidSearch = function (params) {
        if (!params) {
            return false;
        }
        return ((params['word'] || params['q']) && params['tp'] && params['searchType']) ? true : false;
    };
    SiteSearch.prototype.compareParams = function (startingParams, currentParams) {
        var sParams = [];
        var cParams = [];
        var notFound = [];
        var found = [];
        for (var key in startingParams) {
            sParams.push(key);
        }
        for (var key in currentParams) {
            cParams.push(key);
        }
        var currentKey;
        while (currentKey = cParams.pop()) {
            var valid = false;
            for (var i = 0, ilen = sParams.length; i < ilen; i++) {
                if (sParams[i] === currentKey) {
                    found.push(currentKey);
                    valid = true;
                    break;
                }
            }
            if (!valid) {
                notFound.push(currentKey);
            }
        }
        return {
            commonParams: found,
            uniqueParams: notFound
        };
    };
    SiteSearch.prototype.sanitizeSearchParameters = function (params, storedParams) {
        var allParams = merge(params, storedParams);
        var sanitizedParams = {};
        var tmpArr = [];
        if (allParams['q']) {
            allParams['word'] = allParams['q'];
        }
        for (var key in storedParams) {
            tmpArr.push(key);
        }
        var paramKeys = concat(tmpArr, [
            'word',
            'tp',
            'searchType',
            'siteType',
            'page',
            'bf_cat',
            'x1',
            'x2',
            'x3',
            'x4',
            'q1',
            'q2',
            'q3',
            'q4'
        ]);
        paramKeys.map(function (param) {
            if (allParams[param]) {
                sanitizedParams[param] = allParams[param];
            }
        });
        return sanitizedParams;
    };
    SiteSearch.prototype.sendAnalytics = function (appViewCode, searchData, isLoading) {
        var pageCode = 'sitesearch';
        var _satellite = window._satellite;
        var kiwi = window.kiwi;
        isLoading = isLoading === null ? false : ((isLoading === undefined) ? false : isLoading);
        var applicationDataLayer = {
            page: {
                pageCode: pageCode,
                appViewCode: appViewCode,
                isLoading: isLoading,
                searchData: merge({}, searchData)
            }
        };
        try {
            kiwi.mergeDdl(applicationDataLayer);
            if (typeof _satellite !== 'undefined') {
                _satellite.track('cbTrack.viewInDom');
            }
        }
        catch (e) {
            if (typeof _satellite !== 'undefined') {
                _satellite.notify('Something went wrong with kiwi.mergeDDL(): ' + e.message);
            }
        }
    };
    SiteSearch.prototype.onAddFiltersClick = function () {
        this.setState({
            isFiltersActive: !this.state.isFiltersActive
        });
    };
    SiteSearch.prototype.executeSearch = function (searchParams) {
        var params = this.sanitizeSearchParameters(searchParams, this.props.additionalParams);
        this.retrieveSearch(params);
    };
    SiteSearch.prototype.render = function () {
        var _a;
        var _this = this;
        var _b = this.props, destinationURL = _b.destinationURL, additionalParams = _b.additionalParams, tracker = _b.tracker;
        var _c = this.state, word = _c.word, data = _c.data, siteType = _c.siteType, isRetrievingData = _c.isRetrievingData, isFiltersOn = _c.isFiltersOn, isFiltersActive = _c.isFiltersActive;
        var isDataAvailable = data.isDataAvailable, resetURL = data.resetURL, results = data.results, resultStats = data.resultStats, pagination = data.pagination, columns = data.columns, activeFilters = data.activeFilters, banners = data.banners;
        return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "sitesearch", className: $('cb-widget', styles.sitesearch, (_a = {}, _a["" + styles.active] = isFiltersActive, _a)) },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-3" }, (!!columns && results.length !== 0) &&
                        React.createElement(FilterView, { columns: columns, resetURL: resetURL, siteType: siteType, additionalParams: additionalParams, isFiltersOn: isFiltersOn, onBackClick: this.onAddFiltersClick.bind(this), onResetClick: this.executeSearch.bind(this), onUndoClick: this.executeSearch.bind(this), onFilterClick: this.executeSearch.bind(this) })),
                    React.createElement("div", { className: "col-md-9" },
                        React.createElement("div", { className: $(styles.search) },
                            React.createElement("h2", null, "Search Results"),
                            React.createElement("div", { className: $(styles.searchbar) },
                                React.createElement(DefaultSearch, { appId: 42, startValue: word, destinationURL: destinationURL, additionalParams: additionalParams, onSubmit: function (data) {
                                        var params = __assign(__assign(__assign({}, data.params), _this.props.additionalParams), { word: data.word });
                                        _this.executeSearch(params);
                                    } })),
                            (activeFilters && results.length !== 0) &&
                                React.createElement(FilterControlsView, { activeFilters: activeFilters, resetURL: resetURL, onButtonClick: this.onAddFiltersClick.bind(this), onResetClick: this.executeSearch.bind(this) })),
                        isRetrievingData &&
                            React.createElement("div", { role: "progressbar", "aria-valuetext": "Loading\u2026", className: $('cb-loader cb-loader-blue cb-loader-lg', styles.loader) }),
                        (!!results && results.length !== 0) ? (React.createElement("div", { className: $(styles.information) },
                            React.createElement(StatusView, { pagelower: resultStats.pagelower, pageupper: resultStats.pageupper, total: resultStats.total }),
                            React.createElement("hr", null),
                            React.createElement(BannersView, { banners: reject(banners, function (b) { return b.id === 'global-noresults'; }) }),
                            React.createElement(SearchResultsView, { results: results }),
                            React.createElement("hr", null),
                            pagination.pages.length > 1 &&
                                React.createElement(PaginationView, { pagination: pagination, destinationURL: destinationURL, additionalParams: additionalParams, onClick: this.executeSearch.bind(this) })))
                            :
                                React.createElement(React.Fragment, null, (!isRetrievingData && isDataAvailable) &&
                                    React.createElement("div", { "data-cbtrack-searchbanner": 'noresults' },
                                        React.createElement(BannersView, { banners: [find(banners, function (b) { return b.id === 'global-noresults'; })] }),
                                        React.createElement("br", null),
                                        React.createElement("p", null,
                                            React.createElement("strong", null, "No matches for: "),
                                            word),
                                        React.createElement("hr", null),
                                        React.createElement("p", null, "No search results were found. Please make sure that all keywords are spelled correctly or try different or more general keywords. For your convenience, we've provided links to commonly requested information below."),
                                        React.createElement("div", { className: $(styles.group) },
                                            React.createElement("h2", null, "Key Portals"),
                                            React.createElement("ul", null,
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/sat/register" }, "My SAT (registration, sending scores, admission tickets)")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://myap.collegeboard.org/login" }, "My AP (registration, enrollment, class work)")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://clep.collegeboard.org/" }, "My CLEP (registration, sending scores)")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://opportunity.collegeboard.org/" }, "Opportunity Scholarships")))),
                                        React.createElement("div", { className: $(styles.group) },
                                            React.createElement("h2", null, "Score Access"),
                                            React.createElement("ul", null,
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://studentscores.collegeboard.org/home" }, "SAT and PSAT-related Assessment Scores")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://apscore.collegeboard.org/scores" }, "AP Scores")))),
                                        React.createElement("div", { className: $(styles.group) },
                                            React.createElement("h2", null, "Key Tools"),
                                            React.createElement("ul", null,
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/sat/practice/full-length-practice-tests" }, "SAT Practice")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://accuplacer.collegeboard.org/student/practice" }, "Accuplacer Practice")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://cssprofile.collegeboard.org/" }, "CSS Profile")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://bigfuture.collegeboard.org/college-search" }, "College Search")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://bigfuture.collegeboard.org/scholarship-search" }, "Scholarship Search")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://apstudents.collegeboard.org/digital-submission" }, "AP Digital Portfolio")))))))))));
    };
    SiteSearch.prototype.retrieveSearch = function (params, reload) {
        var _this = this;
        if (reload === void 0) { reload = false; }
        var timer = Date.now();
        var requiredParams = {
            'word': this.state.word,
            'tp': this.state.params['tp'],
            'searchType': this.state.params['searchType']
        };
        var finalParams = merge(requiredParams, params);
        if (this.isValidSearch(finalParams)) {
            this.sendAnalytics('loading', {}, true);
            this.setState({
                isRetrievingData: true,
                data: {
                    isDataAvailable: false
                }
            });
            window.scrollTo(0, 0);
            this.props.store.retrieveSearch(finalParams, this.props.destinationURL).then(function (response) {
                var facets = [];
                for (var key in finalParams) {
                    if (key.indexOf('q') === 0 && key.length > 1) {
                        facets.push(params[key]);
                    }
                }
                var numResults = parseInt(response.resultStats.total);
                var appViewCode = 'results';
                if (numResults === 0) {
                    appViewCode = 'noresults';
                }
                _this.sendAnalytics(appViewCode, {
                    type: finalParams['searchType'],
                    term: finalParams['word'],
                    facet: facets,
                    speed: Date.now() - timer,
                    count: parseInt(response.resultStats.total.replace(/,/g, ''))
                }, false);
                var query = queryString.stringify(finalParams).replace(/%20/g, '+');
                var url = '?' + query;
                var compareResults = _this.compareParams(_this.state.startingParams, finalParams);
                if (!_this.state.isBrowserBack || compareResults.uniqueParams.length) {
                    window.history.replaceState({}, '', url);
                }
                if (!reload) {
                    _this.setState({
                        word: finalParams['word'],
                        siteType: finalParams['siteType'],
                        isRetrievingData: false,
                        isFiltersOn: response.activeFilters.length > 0 ? true : false,
                        data: __assign(__assign({}, response), { isDataAvailable: true })
                    });
                }
                else {
                    window.location.href = url;
                }
            });
        }
    };
    SiteSearch.prototype.componentWillReceiveProps = function (nextProps) {
        var params = __assign(__assign(__assign({}, this.state.params), nextProps.additionalParams), nextProps.searchParams);
        this.retrieveSearch(params);
    };
    SiteSearch.prototype.componentDidMount = function () {
        var _this = this;
        this.props.tracker.mounted();
        var params = {};
        window.onpopstate = function (e) {
            // Get searchParams via URL. This is for when people bookmark search results.
            var query = window.top.location.search.replace('?', '');
            params = _this.sanitizeSearchParameters(queryString.parse(query), _this.props.additionalParams);
            if (_this.isValidSearch(params)) {
                _this.retrieveSearch(params, true);
            }
            else {
                window.location.reload();
            }
        };
        params = __assign(__assign(__assign({}, this.state.params), this.props.additionalParams), this.props.searchParams);
        this.retrieveSearch(params);
    };
    SiteSearch.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    SiteSearch = __decorate([
        observer
    ], SiteSearch);
    return SiteSearch;
}(Component));
export { SiteSearch };
