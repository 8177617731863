export default [
    { "name": "SAT", "label": "SAT<sup>&reg;</sup>", "url": "https://collegereadiness.collegeboard.org/sat", "category": "programs", "linkCode": "sat" },
    { "name": "PSAT/NMSQT", "label": "PSAT/NMSQT<sup>&reg;</sup>", "url": "https://collegereadiness.collegeboard.org/psat-nmsqt-psat-10", "category": "programs", "linkCode": "pn" },
    { "name": "PSAT 10", "label": "PSAT<sup>&trade;</sup> 10", "url": "https://collegereadiness.collegeboard.org/psat-nmsqt-psat-10", "category": "programs", "linkCode": "p10" },
    { "name": "PSAT 8/9", "label": "PSAT<sup>&trade;</sup> 8/9", "url": "https://collegereadiness.collegeboard.org/psat-8-9", "category": "programs", "linkCode": "p89" },
    { "name": "SpringBoard", "label": "SpringBoard<sup>&reg;</sup>", "url": "https://springboard.collegeboard.org/", "category": "programs", "linkCode": "sb" },
    { "name": "Pre-AP", "label": "Pre-AP<sup>&reg;</sup>", "url": "https://pre-ap.collegeboard.org", "category": "programs", "linkCode": "preap" },
    { "name": "AP", "label": "AP<sup>&reg;</sup>", "url": "https://ap.collegeboard.org", "category": "programs", "linkCode": "ap" },
    { "name": "Access", "url": "https://bigfuture.collegeboard.org/", "category": "programs", "linkCode": "accs" },
    { "name": "ACCUPLACER", "label": "ACCUPLACER<sup>&reg;</sup>", "url": "https://accuplacer.collegeboard.org/", "category": "programs", "linkCode": "accu" },
    { "name": "CLEP", "label": "CLEP<sup>&reg;</sup>", "url": "https://clep.collegeboard.org/", "category": "programs", "linkCode": "clep" },
    { "name": "CSS Profile", "label": "CSS Profile<sup>&trade;</sup>", "url": "https://cssprofile.collegeboard.org/", "category": "programs", "linkCode": "css" },
    { "name": "Search", "url": "https://collegeboardsearch.collegeboard.org/pastudentsrch/login.action", "category": "programs", "linkCode": "cbsch" },
    { "name": "PowerFAIDS", "label": "PowerFAIDS<sup>&reg;</sup>", "url": "https://www.collegeboard.org/powerfaids", "category": "programs", "linkCode": "pfaid" },
    { "name": "About Us", "url": "https://www.collegeboard.org/about ", "category": "organization", "linkCode": "abt" },
    { "name": "Careers", "url": "https://www.collegeboard.org/about/careers ", "category": "organization", "linkCode": "car" },
    { "name": "Membership", "url": "https://www.collegeboard.org/membership ", "category": "organization", "linkCode": "mem" },
    { "name": "Newsroom", "url": "https://www.collegeboard.org/press ", "category": "organization", "linkCode": "news" },
    { "name": "Research", "url": "http://research.collegeboard.org/", "category": "organization", "linkCode": "rsch" },
    { "name": "Services for Students with Disabilities", "url": "https://www.collegeboard.org/students-with-disabilities", "category": "organization", "linkCode": "ssd" },
    { "name": "Help", "url": "https://pages.collegeboard.org/help", "category": "extra", "linkCode": "help" },
    { "name": "Contact Us", "url": "https://www.collegeboard.org/contact-us", "category": "extra", "linkCode": "contact" },
    { "name": "More", "url": "https://www.collegeboard.org/sitemap", "category": "extra", "linkCode": "morecb" },
    { "name": "Facebook", "url": "https://www.facebook.com/thecollegeboard", "category": "extra", "type": "social", "group": "More", "linkCode": "sm-fb", "glyph": "facebook", "target": "_blank" },
    { "name": "Twitter", "url": "https://twitter.com/collegeboard", "category": "extra", "type": "social", "group": "More", "linkCode": "sm-tw", "glyph": "twitter", "target": "_blank" },
    { "name": "Youtube", "url": "https://www.youtube.com/user/collegeboard", "category": "extra", "type": "social", "group": "More", "linkCode": "sm-yt", "glyph": "youtube", "target": "_blank" },
    { "name": "LinkedIn", "url": "https://www.linkedin.com/company/the-college-board", "category": "extra", "type": "social", "group": "More", "linkCode": "sm-li", "glyph": "linkedin", "target": "_blank" },
    { "name": "Instagram", "url": "https://www.instagram.com/collegeboard/", "category": "extra", "type": "social", "group": "More", "linkCode": "sm-in", "glyph": "instagram", "target": "_blank" },
    { "name": "Doing Business", "url": "https://www.collegeboard.org/about/doing-business", "category": "extra", "linkCode": "bus" },
    { "name": "Compliance", "url": "https://secure.ethicspoint.com/domain/en/report_custom.asp?clientid=18136", "category": "extra", "linkCode": "comp" },
    { "name": "Terms of Use", "url": "https://www.collegeboard.org/site-terms", "category": "extra", "linkCode": "terms" },
    { "name": "Privacy Policy", "url": "https://www.collegeboard.org/privacy-policy/privacy-statement", "category": "extra", "linkCode": "pp" }
];
